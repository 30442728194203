import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { formulaExamples } from './formula-examples';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum toolTipText {
  copy = 'Copy',
  copied = 'Copied',
}

@Component({
  selector: 'cc-formula-helper',
  templateUrl: './formula-helper.component.html',
  styleUrls: ['./formula-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaHelperComponent {
  examples = Array.from(formulaExamples);
  selectedExample = null;
  tooltipText = toolTipText.copy;

  @ViewChild(NgbTooltip) tooltip: NgbTooltip;
  constructor(private clipboard: Clipboard) {}

  selectExample(example: { label: string; link: string; syntax: string; formula: string }) {
    this.selectedExample = example;
  }

  clearExample() {
    this.selectedExample = null;
  }

  copyToClipboard() {
    this.clipboard.copy(this.selectedExample.formula);
    this.tooltipText = toolTipText.copied;
  }

  restoreToolTip() {
    this.tooltip.close();
    this.tooltipText = toolTipText.copy;
  }
}
