import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';

import { FormDateComponent } from 'app/form-fields/date/edit/form-date.component';
import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';

import { FormDateDiffComponent } from './edit/diff/form-date-diff.component';
import { DateColumnComponent } from './register/date-column.component';
import { DateFilterComponent } from './register/date-filter.component';
import { DateTemplateComponent } from './template/date-template.component';
import { DateTemplatePreviewComponent } from './template/preview/date-template-preview.component';
import { DateThumbnailComponent } from './thumbnail/date-thumbnail.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    DateFilterComponent,
    DateColumnComponent,
    DateThumbnailComponent,
    FormDateComponent,
    DateTemplatePreviewComponent,
    DateTemplateComponent,
    FormDateDiffComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    MomentModule,
    SharedModule,
    SegmentModule,
    FormFieldsCommonModule,
  ],
  exports: [
    DateColumnComponent,
    DateColumnComponent,
    FormDateComponent,
    DateTemplatePreviewComponent,
    DateTemplateComponent,
  ],
})
export class DateModule {}
