import { Component, Input } from '@angular/core';

import { YesNoColumn } from './yes-no-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'yes-no-column.component.html',
  styleUrls: ['../../common/register/form-field-column/form-field-column.component.scss'],
})
export class YesNoColumnComponent extends FormFieldColumnComponent {
  @Input()
  column: YesNoColumn;
}
