<cc-filter-trigger
  [filterTemplate]="multiSelectFilterTemplate"
  (click)="toggleFilter()"
  (keydown)="toggleFilter()"
  [active]="status"
></cc-filter-trigger>

<ng-template #multiSelectFilterTemplate>
  <form
    (mouseup)="$event.stopPropagation()"
    (ngSubmit)="applyFilter()"
    class="reg-view-filter-container"
    #filterForm="ngForm"
    data-test="filter-template"
  >
    <div class="form-group">
      <ng-select
        [items]="model.options"
        [multiple]="true"
        [(ngModel)]="model.selectedValues"
        [loading]="loading"
        name="users"
        placeholder="Select or search"
        i18n-placeholder="Select or search"
        class="select-multiple"
        bindValue="value"
      >
      </ng-select>
    </div>
    <hr class="hr" />
    <div class="filter-button-container">
      <button
        (click)="resetFilter()"
        type="button"
        class="btn btn-link btn-sm reg-view-filter-button"
        [disabled]="!status"
        data-test="reset-filter-button"
      >
        <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
        <span i18n>Remove Filter</span>
      </button>
      <button
        type="submit"
        class="btn btn-link btn-sm reg-view-filter-button"
        [disabled]="!model.selectedValues.length"
        data-test="apply-filter-button"
      >
        <i class="cc-icon cc-icon-filter" aria-hidden="true"></i>
        <span i18n>Apply Filter</span>
      </button>
    </div>
  </form>
</ng-template>
