import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormDateComparator implements FormFieldComparator {
  isEqual(previous, next) {
    const prevStartDate = new Date(previous.startDate).getTime();
    const nextStartDate = new Date(next.startDate).getTime();

    if (previous.endDate) {
      const prevEndDate = new Date(previous.endDate).getTime();
      const nextEndDate = new Date(next.endDate).getTime();
      return prevStartDate === nextStartDate && prevEndDate === nextEndDate;
    }

    return prevStartDate === nextStartDate;
  }
}
