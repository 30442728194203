<div class="column" [class.column-sm]="column.static">
  <div class="content">
    <ng-container *ngIf="showEmptyCell; else loadPerson">
      <span>-</span>
    </ng-container>

    <ng-template #loadPerson>
      <ng-container *ngIf="person$ | async as person; else loading">
        <cc-user-avatar [user]="person" *ngIf="!person.userDoesNotExist" class="sm-mr-2"></cc-user-avatar>
        <div data-test="person-name">
          {{ person.fullName }}
        </div>
      </ng-container>
    </ng-template>

    <ng-template #loading>
      <i class="cc-icon cc-icon-spinner" aria-label="Loading spinner"></i>
    </ng-template>
  </div>
</div>
