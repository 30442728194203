import { DateHeaderColumn } from './date-header-column';
import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class DateColumn extends FormFieldColumn {
  date: Date;
  isDateAndTime: boolean;
  isSingleDate: boolean;

  constructor(item, headerColumn: DateHeaderColumn, renderer) {
    super(item, renderer);

    this.date = headerColumn.dateRange && headerColumn.end ? item.endDate : item.startDate;
    this.isDateAndTime = item.isDateAndTime;
    this.isSingleDate = item.isSingleDate;
  }
}
