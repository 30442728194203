<form #form="ngForm" novalidate (submit)="form.valid && onSubmit()">
  <div class="modal-header">
    <h4 class="h4 modal-title">
      <span i18n>Send {{ itemName }} as PDF</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body email-modal-body">
    <div class="form-group row">
      <label i18n for="email" class="default-label col-form-label col-sm-2">Email</label>
      <div [ngClass]="{ 'has-danger': form.submitted && email.invalid }" class="col-sm-10">
        <input
          type="email"
          placeholder="Type email"
          i18n-placeholder="Type email"
          class="form-control"
          name="email"
          id="send-pdf-email"
          required
          #email="ngModel"
          [(ngModel)]="emails"
          ccFocus
        />
        <small class="text-muted">You can input multiple email addresses separated by semicolon - ;</small>
        <div class="sm-text-default-red" *ngIf="form.submitted && !emails" i18n>Email is required</div>
        <div class="sm-text-default-red" i18n *ngIf="form.submitted && invalid">Invalid email(s):</div>
        <div class="sm-text-default-red" i18n *ngIf="form.submitted && invalid">
          <div *ngFor="let e of invalidMails">
            {{ e }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group text-center">
      <button type="submit" class="btn btn-success btn-block" i18n [ccBusyButton]="working">
        Send email
      </button>
    </div>
  </div>
</form>
