<div class="column" [class.column-sm]="column.static">
  <div *ngIf="column.selectedItems && !!column.selectedItems.length" class="item-content-category-items">
    <span
      *ngFor="let selectedCategoryItem of column.selectedItems"
      class="category-item-selected color-choice-item category-item"
      [ngClass]="selectedCategoryItem.style || styleVariants.None"
      >{{ selectedCategoryItem.value }}
    </span>
  </div>
  <div *ngIf="!column.selectedItems || !column.selectedItems.length" class="text-muted" i18n>-</div>
</div>
