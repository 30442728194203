<ng-select
  #ngSelectDropdown
  *ngIf="shouldDisplayDropDown"
  #dropdown
  [items]="items"
  bindLabel="label"
  (change)="onSelectHandler($event)"
  [clearable]="false"
  [isOpen]="true"
  [closeOnSelect]="false"
  class="formula-suggestions-dropdown"
>
  <ng-template ng-option-tmp let-item="item">
    <div (mouseover)="onItemHover(item)" class="formula-suggestion-item">
      <span class="formula-label">{{ item }}</span>
      <span *ngIf="markedItem === item" class="formula-info">{{ formulaInfo }}</span>
    </div>
  </ng-template>
</ng-select>
