import { IPersonField } from '@site-mate/dashpivot-shared-library';

import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class PersonColumn extends FormFieldColumn {
  userIds?: string[];

  constructor(item: IPersonField, renderer) {
    super(item, renderer);
    this.userIds = item.data?.userIds;
  }
}
