import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormSignatureListComparator implements FormFieldComparator {
  isEqual(previous, next) {
    let equal = true;
    if (previous.signatures && next.signatures) {
      if (previous.signatures.length !== next.signatures.length) {
        equal = false;
      } else {
        for (let j = 0; j < previous.signatures.length; j++) {
          if (previous.signatures[j].signatureUrl !== next.signatures[j].signatureUrl) {
            equal = false;
            break;
          }
        }
      }
    } else if (
      (previous.signatures && previous.signatures.length > 0) ||
      (next.signatures && next.signatures.length > 0)
    ) {
      equal = false;
    }

    return equal;
  }
}
