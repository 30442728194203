import { Component, OnInit, ViewChild, Input } from '@angular/core';
import moment from 'moment-timezone';

import { SegmentService } from 'app/segment/segment.service';
import { NgbDateUtil } from 'app/shared/service/ngb-date-util.service';

import { DateFilter } from './date-filter';
import { FormRegisterViewFilterBaseComponent } from '../../common/register/filters/form-register-view-filter-base.component';

@Component({
  selector: 'cc-reg-view-date-filter',
  templateUrl: 'date-filter.component.html',
  styleUrls: ['../../common/register/filters/form-register-view-filter.component.scss'],
})
export class DateFilterComponent extends FormRegisterViewFilterBaseComponent implements OnInit {
  get filterContextType(): string {
    return 'Date';
  }
  @ViewChild('dateFilterPopover')
  filter;

  radioModel;
  startDate;
  endDate;

  @Input() model: DateFilter;

  constructor(protected segmentService: SegmentService) {
    super(segmentService);
  }

  ngOnInit(): void {
    if (this.model && this.model.startDate) {
      this.startDate = NgbDateUtil.toObject(this.model.startDate.toObject());
    }
    if (this.model && this.model.endDate) {
      this.endDate = NgbDateUtil.toObject(this.model.endDate.toObject());
    }
    this.updateRadioValue();
    super.ngOnInit();
  }

  applyFilter() {
    if (this.startDate) {
      this.model.startDate = NgbDateUtil.toMoment(this.startDate).startOf('day');
    } else {
      delete this.model.startDate;
    }

    if (this.endDate) {
      this.model.endDate = NgbDateUtil.toMoment(this.endDate).endOf('day');
    } else {
      delete this.model.endDate;
    }

    super.applyFilter();
  }

  resetFilter() {
    delete this.startDate;
    delete this.endDate;
    super.resetFilter();
  }

  onStartDateChange(e) {
    this.startDate = e;
    this.updateRadioValue();
  }

  onEndDateChange(e) {
    this.endDate = e;
    this.updateRadioValue();
  }

  updateRadioValue() {
    if (this.same(this.today, this.today)) {
      this.radioModel = 'today';
    } else if (this.same(this.startOfWeek, this.endOfWeek)) {
      this.radioModel = 'currentWeek';
    } else if (this.same(this.startOfMonth, this.endOfMonth)) {
      this.radioModel = 'currentMonth';
    } else if (this.same(this.startOfYear, this.endOfYear)) {
      this.radioModel = 'currentYear';
    } else if (this.same(this.yesterday, this.yesterday)) {
      this.radioModel = 'yesterday';
    } else if (this.same(this.sixDaysAgo, this.today)) {
      this.radioModel = 'past7days';
    } else if (this.same(this.twentyNineDaysAgo, this.today)) {
      this.radioModel = 'past30days';
    } else if (this.same(this.startOfLastWeek, this.endOfLastWeek)) {
      this.radioModel = 'lastCalendarWeek';
    } else if (this.same(this.startOfLastMonth, this.endOfLastMonth)) {
      this.radioModel = 'lastCalendarMonth';
    } else if (this.same(this.startOfLastYear, this.endOfLastYear)) {
      this.radioModel = 'lastCalendarYear';
    } else {
      this.radioModel = '';
    }
  }

  same(d1, d2) {
    if (this.startDate && this.endDate) {
      return this.equal(this.startDate, d1) && this.equal(this.endDate, d2);
    }
    return false;
  }

  equal(d1, d2) {
    return d1.year === d2.year && d1.month === d2.month && d1.day === d2.day;
  }

  get today() {
    return NgbDateUtil.toObject(moment().toObject());
  }

  get startOfWeek() {
    return NgbDateUtil.toObject(moment().startOf('week').toObject());
  }

  get endOfWeek() {
    return NgbDateUtil.toObject(moment().endOf('week').toObject());
  }

  get startOfMonth() {
    return NgbDateUtil.toObject(moment().startOf('month').toObject());
  }

  get endOfMonth() {
    return NgbDateUtil.toObject(moment().endOf('month').toObject());
  }

  get startOfYear() {
    return NgbDateUtil.toObject(moment().startOf('year').toObject());
  }

  get endOfYear() {
    return NgbDateUtil.toObject(moment().endOf('year').toObject());
  }

  get yesterday() {
    const past = moment().subtract(1, 'days');
    return NgbDateUtil.toObject(past.toObject());
  }

  get sixDaysAgo() {
    const past6day = moment().subtract(6, 'days');
    return NgbDateUtil.toObject(past6day.toObject());
  }

  get twentyNineDaysAgo() {
    const past29day = moment().subtract(29, 'days');
    return NgbDateUtil.toObject(past29day.toObject());
  }

  get startOfLastWeek() {
    const past = moment().subtract(1, 'weeks');
    return NgbDateUtil.toObject(past.startOf('week').toObject());
  }

  get endOfLastWeek() {
    const past = moment().subtract(1, 'weeks');
    return NgbDateUtil.toObject(past.endOf('week').toObject());
  }

  get startOfLastMonth() {
    const past = moment().subtract(1, 'months');
    return NgbDateUtil.toObject(past.startOf('month').toObject());
  }

  get endOfLastMonth() {
    const past = moment().subtract(1, 'months');
    return NgbDateUtil.toObject(past.endOf('month').toObject());
  }

  get startOfLastYear() {
    const past = moment().subtract(1, 'years');
    return NgbDateUtil.toObject(past.startOf('year').toObject());
  }

  get endOfLastYear() {
    const past = moment().subtract(1, 'years');
    return NgbDateUtil.toObject(past.endOf('year').toObject());
  }

  setCreatedAtAsToday() {
    this.startDate = this.today;
    this.endDate = this.today;
  }

  setCreatedAtAsCurrentWeek() {
    this.startDate = this.startOfWeek;
    this.endDate = this.endOfWeek;
  }

  setCreatedAtAsCurrentMonth() {
    this.startDate = this.startOfMonth;
    this.endDate = this.endOfMonth;
  }

  setCreatedAtAsCurrentYear() {
    this.startDate = this.startOfYear;
    this.endDate = this.endOfYear;
  }

  setCreatedAtAsYesterday() {
    this.startDate = this.yesterday;
    this.endDate = this.yesterday;
  }

  setCreatedAtAsPast7Days() {
    this.startDate = this.sixDaysAgo;
    this.endDate = this.today;
  }

  setCreatedAtAsPast30Days() {
    this.startDate = this.twentyNineDaysAgo;
    this.endDate = this.today;
  }

  setCreatedAtAsLastCalendarWeek() {
    this.startDate = this.startOfLastWeek;
    this.endDate = this.endOfLastWeek;
  }

  setCreatedAtAsLastCalendarMonth() {
    this.startDate = this.startOfLastMonth;
    this.endDate = this.endOfLastMonth;
  }

  setCreatedAtAsLastCalendarYear() {
    this.startDate = this.startOfLastYear;
    this.endDate = this.endOfLastYear;
  }

  get hasFilterData() {
    return this.startDate || this.endDate;
  }
}
