import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class YesNoColumn extends FormFieldColumn {
  checked: string;

  constructor(item, renderer) {
    super(item, renderer);

    this.checked = item.checked;
  }
}
