import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AttachmentModule } from './attachment/attachment.module';
import { CategoryModule } from './category/category.module';
import { FormFieldsCommonModule } from './common/common.module';
import { CounterModule } from './counter/counter.module';
import { CreatedByModule } from './created-by/created-by.module';
import { CreatedDateModule } from './created-date/created-date.module';
import { DateModule } from './date/date.module';
import { FormFieldComparatorFactory } from './form-field-comparator-factory';
import { FormFieldTemplateFactory } from './form-field-template-factory';
import { FormFieldVersionDiffFactory } from './form-field-version-diff-factory';
import { HeaderFooterFieldItemModule } from './header-footer/header-footer-field-item.module';
import { MultiLineInputModule } from './multi-line-input/multi-line-input.module';
import { PageBreakModule } from './page-break/page-break.module';
import { PersonModule } from './person/person.module';
import { PhotoModule } from './photo/photo.module';
import { PrefilledTextModule } from './prefilled-text/prefilled-text.module';
import { SignatureModule } from './signature/signature.module';
import { SignatureArrayModule } from './signature-array/signature-array.module';
import { SignonTableModule } from './signon-table/signon-table.module';
import { SingleLineInputModule } from './single-line-input/single-line-input.module';
import { SketchModule } from './sketch/sketch.module';
import { TableModule } from './table/table.module';
import { UserModule } from './user/user.module';
import { YesNoModule } from './yes-no/yes-no.module';

@NgModule({
  imports: [
    FormFieldsCommonModule,
    SketchModule,
    YesNoModule,
    PrefilledTextModule,
    SingleLineInputModule,
    SignatureModule,
    SignatureArrayModule,
    PhotoModule,
    AttachmentModule,
    CategoryModule,
    PersonModule,
    TableModule,
    DateModule,
    UserModule,
    MultiLineInputModule,
    CounterModule,
    SignonTableModule,
    PageBreakModule,
    CommonModule,
    HeaderFooterFieldItemModule,
    CreatedByModule,
    CreatedDateModule,
  ],
  exports: [
    FormFieldsCommonModule,
    YesNoModule,
    SketchModule,
    PrefilledTextModule,
    SingleLineInputModule,
    SignatureModule,
    SignatureArrayModule,
    PhotoModule,
    AttachmentModule,
    CategoryModule,
    PersonModule,
    TableModule,
    DateModule,
    UserModule,
    MultiLineInputModule,
    CounterModule,
    SignonTableModule,
    PageBreakModule,
    HeaderFooterFieldItemModule,
    CreatedByModule,
    CreatedDateModule,
  ],
  providers: [FormFieldTemplateFactory, FormFieldVersionDiffFactory, FormFieldComparatorFactory],
})
export class FormFieldsModule {}
