import { Component, Input } from '@angular/core';

import { FormFieldColumnComponent } from 'app/form-fields/common/register/form-field-column/form-field-column.component';

@Component({
  selector: 'cc-location-column',
  templateUrl: './location-column.component.html',
})
export class LocationColumnComponent extends FormFieldColumnComponent {
  @Input() column;
}
