import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormSignonTableComparator implements FormFieldComparator {
  isEqual(previous, next) {
    if (previous.entries?.length !== next.entries?.length) {
      return false;
    }

    const diff = previous.entries?.find((entry, index) => entry._id !== next.entries[index]._id);
    return !diff;
  }
}
