import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'app/shared/shared.module';

import { FormMultiLineInputDiffComponent } from './edit/diff/form-multi-line-input-diff.component';
import { FormMultiInputComponent } from './edit/form-multi-input.component';
import { MultiLineInputTemplatePreviewComponent } from './template/preview/multi-line-input-template-preview.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    FormMultiInputComponent,
    MultiLineInputTemplatePreviewComponent,
    FormMultiLineInputDiffComponent,
  ],
  imports: [CommonModule, FormsModule, NgbModule, SharedModule, FormFieldsCommonModule],
  exports: [FormMultiInputComponent, MultiLineInputTemplatePreviewComponent],
})
export class MultiLineInputModule {}
