<div class="column" [class.column-sm]="column.static">
  <div *ngFor="let attachment of column.attachments">
    <div class="d-flex justify-content-between my-2 attachment-row">
      <div class="text-nowrap">
        <a
          [href]="attachment.urlDownload"
          target="_blank"
          rel="noopener"
          class="my-1 text-main"
          (click)="$event.stopPropagation()"
        >
          <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>{{ attachment.filename }}</a
        >
      </div>
    </div>
    <hr class="hr" />
  </div>
</div>
