import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormSketchDiffComponent } from './edit/diff/form-sketch-diff.component';
import { FormSketchModalComponent } from './edit/form-sketch-modal.component';
import { SketchEditComponent } from './edit/sketch-edit.component';
import { SketchPadImageComponent } from './edit/sketch-pad-image.component';
import { SketchPadComponent } from './edit/sketch-pad.component';
import { SketchTemplatePreviewComponent } from './template/preview/sketch-template-preview.component';
import { SketchTemplateComponent } from './template/sketch-template.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    SketchEditComponent,
    SketchPadImageComponent,
    FormSketchModalComponent,
    SketchPadComponent,
    SketchTemplateComponent,
    SketchTemplatePreviewComponent,
    FormSketchDiffComponent,
  ],
  imports: [CommonModule, FormsModule, NgbModule, FormFieldsCommonModule],
  exports: [
    SketchEditComponent,
    SketchPadImageComponent,
    SketchTemplateComponent,
    SketchTemplatePreviewComponent,
  ],
})
export class SketchModule {}
