import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormPageBreakDiffComponent } from './edit/diff/form-page-break-diff.component';
import { FormPageBreakComponent } from './edit/form-page-break.component';
import { PageBreakTemplatePreviewComponent } from './template/page-break-template-preview/page-break-template-preview.component';
import { PageBreakTemplateComponent } from './template/page-break-template.component';

@NgModule({
  declarations: [
    FormPageBreakComponent,
    PageBreakTemplateComponent,
    PageBreakTemplatePreviewComponent,
    FormPageBreakDiffComponent,
  ],
  imports: [CommonModule, UIComponentsModule],
  exports: [
    FormPageBreakComponent,
    PageBreakTemplateComponent,
    PageBreakTemplatePreviewComponent,
    FormPageBreakDiffComponent,
  ],
})
export class PageBreakModule {}
