import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormTableComparator implements FormFieldComparator {
  isEqual(previous, next) {
    if (previous.rows && next.rows && previous.rows.length !== next.rows.length) {
      return false;
    }

    if (previous.rows) {
      for (let j = 0; j < previous.rows.length; j++) {
        if (JSON.stringify(previous.rows[j].columns) !== JSON.stringify(next.rows[j].columns)) {
          return false;
        }
      }
    }

    return true;
  }
}
