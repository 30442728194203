import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

import { IUserWithSummaryOrWithoutData, PersonFieldUtils } from '@site-mate/dashpivot-shared-library';

import { FormRegisterViewFilterBaseComponent } from 'app/form-fields/common/register/filters/form-register-view-filter-base.component';
import { MultiSelectFilter } from 'app/form-fields/common/register/filters/multi-select/multi-select-filter';
import { RegisterViewFilterService } from 'app/form-fields/common/register/filters/register-view-filter.service';
import { SegmentService } from 'app/segment/segment.service';
import { SitemateUsersService } from 'app/users/sitemate-users.service';

import { ErrorHandler } from '../../../shared/service/error-handler.service';

@Component({
  selector: 'cc-person-column-header-filter',
  templateUrl:
    '../../common/register/filters/multi-select/form-register-view-multi-select-filter.component.html',
  styleUrls: ['../../common/register/filters/form-register-view-filter.component.scss'],
})
export class PersonColumnHeaderFilterComponent
  extends FormRegisterViewFilterBaseComponent
  implements OnInit, OnDestroy
{
  get filterContextType(): string {
    return 'Person';
  }
  @Input() model: MultiSelectFilter;
  private unSubscribe = new Subject<void>();
  loading = true;
  optionLoadTriggered = false;

  constructor(
    protected readonly segmentService: SegmentService,
    protected readonly registerViewFilterService: RegisterViewFilterService,
    protected readonly sitemateUsersService: SitemateUsersService,
    protected readonly errorHandler: ErrorHandler,
  ) {
    super(segmentService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.model && this.model.selectedValues && this.model.selectedValues.length > 0) {
      this.loadOptions();
    }
  }

  toggleFilter() {
    if (!this.optionLoadTriggered) {
      this.loadOptions();
    }
  }

  private loadOptions() {
    this.optionLoadTriggered = true;
    this.registerViewFilterService
      .getFilterListOptions(this.model.templateId, this.model.fieldId)
      .pipe(
        map((ids) => this.sitemateUsersService.getUserDataSubjects(ids as string[])),
        map((observablesMap) => Array.from(observablesMap.values())),
        mergeMap((observables) => combineLatest(observables)),
        takeUntil(this.unSubscribe),
      )
      .subscribe({
        next: (userProfiles: Array<IUserWithSummaryOrWithoutData>) => {
          this.model.options = userProfiles.filter(PersonFieldUtils.isUserSummary).map((user) => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
          }));
          this.loading = false;
        },
        error: (error) => {
          this.errorHandler.handle(error);
        },
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
