import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormPersonComparator implements FormFieldComparator {
  isEqual(previous, next) {
    const previousValue = previous?.data?.userIds ?? [];
    const nextValue = next?.data?.userIds ?? [];

    return previousValue.join() === nextValue.join();
  }
}
