import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class SignatureArrayColumn extends FormFieldColumn {
  signatures: any[];
  isManualSignature: boolean;

  constructor(item, renderer) {
    super(item, renderer);

    this.signatures = item.signatures;
    this.isManualSignature = item.isManualSignature;
  }
}
