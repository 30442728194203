import { isEmpty } from 'lodash-es';
import moment from 'moment-timezone';

import { Filter } from 'app/form-fields/common/register/filters/filter';

export class DateFilter extends Filter {
  startDate: moment.Moment;
  endDate: moment.Moment;

  constructor(startDate, endDate, renderer) {
    super(renderer);
    this.startDate = startDate;
    this.endDate = endDate;
  }

  queryParams() {
    const params: any = {};

    if (this.startDate) {
      params.startDate = +moment(this.startDate);
    }

    if (this.endDate) {
      params.endDate = +moment(this.endDate);
    }

    if (!isEmpty(params)) {
      return params;
    }

    return null;
  }

  get status() {
    return this.startDate || this.endDate ? 1 : 0;
  }

  set(params) {
    if (!params) {
      this.reset();
      return;
    }
    this.startDate = params.startDate ? moment(params.startDate) : undefined;
    this.endDate = params.endDate ? moment(params.endDate) : undefined;
  }

  reset() {
    delete this.startDate;
    delete this.endDate;
  }
}
