<div class="column" [class.column-sm]="column.static">
  <div *ngIf="column.signatures">
    <div *ngIf="!column.signatures.length" class="text-muted" i18n>-</div>
    <div *ngIf="!!column.signatures.length">
      <cc-form-signature-list
        *ngIf="column.signatures"
        [signatures]="column.signatures"
      ></cc-form-signature-list>
    </div>
  </div>
  <div *ngIf="!column.signatures" class="text-muted">-</div>
</div>
