import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'app/shared/shared.module';

import { PrefilledTextEditComponent } from './edit/prefilled-text-edit.component';
import { PrefilledTextTemplatePreviewComponent } from './template/preview/prefilled-text-template-preview.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [PrefilledTextEditComponent, PrefilledTextTemplatePreviewComponent],
  imports: [CommonModule, SharedModule, NgbModule, FormFieldsCommonModule],
  exports: [PrefilledTextEditComponent, PrefilledTextTemplatePreviewComponent, NgbModule],
})
export class PrefilledTextModule {}
