import { FormFieldComparator } from 'app/form-fields/common/edit/form-field-comparator';

export class FormCategoryComparator implements FormFieldComparator {
  isEqual(previous, next) {
    previous.selectedItems = previous.selectedItems ? previous.selectedItems.map((i) => i.value) : [];
    next.selectedItems = next.selectedItems ? next.selectedItems.map((i) => i.value) : [];

    return JSON.stringify(previous.selectedItems) === JSON.stringify(next.selectedItems);
  }
}
