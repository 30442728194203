import { Injectable } from '@angular/core';

import { SupportedFormulas, SupportedFormulasWithInfo } from '@site-mate/dashpivot-shared-library';

@Injectable({
  providedIn: 'root',
})
export class FormulaSuggestionService {
  readonly defaultSuggestions = ['AVERAGE', 'DATE', 'DATEDIF', 'IF', 'MAX', 'MIN', 'SUM', 'TIMEDIF'];
  readonly maxSuggestions = 8;
  readonly allSuggestions = [];
  private readonly formulasWithInfo: Map<string, Record<string, string>>;

  constructor() {
    this.formulasWithInfo = SupportedFormulasWithInfo;
    this.allSuggestions = Array.from(new Set(SupportedFormulas)).sort();
  }

  getUpdatedSuggestion(formula: string, suggestion: string): string {
    const lastParenthesisIndex = this.getLastParenthesisIndex(formula);
    const beforeParenthesis = formula.slice(0, lastParenthesisIndex + 1);
    return `${beforeParenthesis}${suggestion}`;
  }

  filterSuggestions(searchTerm: string): string[] {
    if (searchTerm === '=') {
      return this.defaultSuggestions;
    }
    const parsedTerm = this.parseSearchTerm(searchTerm);

    return parsedTerm === '' ? [] : this.getFirstSuggestions(parsedTerm);
  }

  getFormulaInfo(formula: string): Record<string, string> {
    return this.formulasWithInfo.get(formula) || {};
  }

  getRemainingFormulaSuggestion(formula = '', suggestion: string): string {
    if (!formula) {
      return '';
    }
    const formulaWithSuggestion = this.getUpdatedSuggestion(formula, suggestion);
    const remainingSuggestion = formulaWithSuggestion.slice(formula.length);

    return remainingSuggestion;
  }

  private getFirstSuggestions(searchTerm: string): string[] {
    return this.allSuggestions
      .filter((expression) => expression.startsWith(searchTerm))
      .slice(0, this.maxSuggestions);
  }

  private parseSearchTerm(formula: string): string {
    return formula.slice(this.getLastParenthesisIndex(formula) + 1).toUpperCase();
  }

  private getLastParenthesisIndex(formula: string): number {
    const lastParenthesisIndex = formula.lastIndexOf('(');
    const hasParenthesis = lastParenthesisIndex !== -1;

    return hasParenthesis ? lastParenthesisIndex : 0;
  }
}
