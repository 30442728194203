import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';

import { BadgeComponent, ButtonComponent, TooltipDirective } from '@site-mate/global-web-ui';

import { PhotoModule } from 'app/form-fields/photo/photo.module';
import { SignatureModule } from 'app/form-fields/signature/signature.module';
import { SignatureArrayModule } from 'app/form-fields/signature-array/signature-array.module';
import { SamModule } from 'app/sam/sam.module';
import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormTableDiffComponent } from './edit/diff/form-table-diff.component';
import { FormTableColumnComponent } from './edit/form-table-column.component';
import { FormTableCoreComponent } from './edit/form-table-core.component';
import { FormTableNavigationDirective } from './edit/form-table-navigation.directive';
import { FormTableComponent } from './edit/form-table.component';
import { FormTableDateCellComponent } from './edit-cells/date/date.component';
import { FormFormulaCellComponent } from './edit-cells/formula/formula.component';
import { FormTableListCellComponent } from './edit-cells/list/form-table-list-cell.component';
import { FormTableListPropertyComponent } from './edit-cells/list-property/form-table-list-property-cell.component';
import { ListPropertyFormService } from './edit-cells/list-property-form.service';
import { FormTableLocationCellComponent } from './edit-cells/location/form-table-location-cell.component';
import { FormTableNumberCellComponent } from './edit-cells/number/number.component';
import { FormTablePhotoCellComponent } from './edit-cells/photo/photo.component';
import { FormTableCellHostDirective } from './edit-cells/table-cell-host.directive';
import { FormTableTextCellComponent } from './edit-cells/text/text.component';
import { FormTableTimeCellComponent } from './edit-cells/time/time.component';
import { TableColumnService } from './service/table-column-service';
import { TableColumnTemplateComponent } from './template/app-table-column.component';
import { TableTemplateComponent } from './template/app-table.component';
import { FormulaSuggestionsDropdownComponent } from './template/formula-bar/formula-dropdown/formula-suggestions-dropdown.component';
import { FormulaHelperComponent } from './template/formula-bar/formula-helper/formula-helper.component';
import { AttachmentColumnTemplatePreviewComponent } from './template/preview/columns/attachment/attachment-column-template-preview.component';
import { DateColumnTemplatePreviewComponent } from './template/preview/columns/date/date-column-template-preview.component';
import { FormulaColumnTemplatePreviewComponent } from './template/preview/columns/formula/formula-column-template-preview.component';
import { GenericColumnTemplatePreviewComponent } from './template/preview/columns/generic/generic-column-template-preview.component';
import { ListColumnTemplatePreviewComponent } from './template/preview/columns/list/list-column-template-preview.component';
import { LocationColumnTemplatePreviewComponent } from './template/preview/columns/location/location-column-template-preview.component';
import { ManualSignatureColumnTemplatePreviewComponent } from './template/preview/columns/manual-signature/manual-signature-column-template-preview.component';
import { NumberColumnTemplatePreviewComponent } from './template/preview/columns/number/number-column-template-preview.component';
import { PhotoColumnTemplatePreviewComponent } from './template/preview/columns/photo/photo-column-template-preview.component';
import { PrefilledTextColumnTemplatePreviewComponent } from './template/preview/columns/prefilled-text/prefilled-text-column-template-preview.component';
import { SignatureColumnTemplatePreviewComponent } from './template/preview/columns/signature/signature-column-template-preview.component';
import { TextColumnTemplatePreviewComponent } from './template/preview/columns/text/text-column-template-preview.component';
import { TimeColumnTemplatePreviewComponent } from './template/preview/columns/time/time-column-template-preview.component';
import { TableColumnTemplatePreviewHostDirective } from './template/preview/table-column-template-preview-host.directive';
import { TableTemplatePreviewComponent } from './template/preview/table-template-preview.component';
import { TemplateTableFormulaCellComponent } from './template/table-cell-items/formula/template-table-formula-cell.component';
import { TableColumnListItemTemplateComponent } from './template/table-cell-items/list/app-table-column-list-item.component';
import { TemplateTableListCellComponent } from './template/table-cell-items/list/template-table-list-cell.component';
import { TemplateTableLocationCellComponent } from './template/table-cell-items/location/template-table-location-cell/template-table-location-cell.component';
import { TableTypeSwitchComponent } from './template/table-type-switch/table-type-switch.component';
import { CategoryModule } from '../category/category.module';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    TableTemplateComponent,
    TableColumnTemplateComponent,
    TableColumnListItemTemplateComponent,
    TemplateTableListCellComponent,
    TemplateTableFormulaCellComponent,
    TableTypeSwitchComponent,
    FormTableComponent,
    FormTableColumnComponent,
    FormTableCellHostDirective,
    FormTableCoreComponent,
    FormTableListCellComponent,
    FormTableListPropertyComponent,
    FormTableDateCellComponent,
    FormTableTimeCellComponent,
    FormTableTextCellComponent,
    FormTablePhotoCellComponent,
    FormTableNumberCellComponent,
    FormFormulaCellComponent,
    TableTemplatePreviewComponent,
    TableColumnTemplatePreviewHostDirective,
    TextColumnTemplatePreviewComponent,
    PrefilledTextColumnTemplatePreviewComponent,
    DateColumnTemplatePreviewComponent,
    TimeColumnTemplatePreviewComponent,
    ListColumnTemplatePreviewComponent,
    NumberColumnTemplatePreviewComponent,
    SignatureColumnTemplatePreviewComponent,
    AttachmentColumnTemplatePreviewComponent,
    FormulaColumnTemplatePreviewComponent,
    ManualSignatureColumnTemplatePreviewComponent,
    PhotoColumnTemplatePreviewComponent,
    GenericColumnTemplatePreviewComponent,
    FormTableDiffComponent,
    FormTableNavigationDirective,
    FormulaSuggestionsDropdownComponent,
    FormulaHelperComponent,
    TemplateTableLocationCellComponent,
    FormTableLocationCellComponent,
    LocationColumnTemplatePreviewComponent,
  ],
  exports: [
    TableTemplateComponent,
    TableTypeSwitchComponent,
    FormTableComponent,
    FormTableCoreComponent,
    TableTemplatePreviewComponent,
    TextColumnTemplatePreviewComponent,
    PrefilledTextColumnTemplatePreviewComponent,
    DateColumnTemplatePreviewComponent,
    ListColumnTemplatePreviewComponent,
    NumberColumnTemplatePreviewComponent,
    SignatureColumnTemplatePreviewComponent,
    AttachmentColumnTemplatePreviewComponent,
    FormulaColumnTemplatePreviewComponent,
    ManualSignatureColumnTemplatePreviewComponent,
    PhotoColumnTemplatePreviewComponent,
    GenericColumnTemplatePreviewComponent,
    FormulaSuggestionsDropdownComponent,
    FormulaHelperComponent,
    FormTableLocationCellComponent,
  ],
  providers: [TableColumnService, ListPropertyFormService, DatePipe],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    MomentModule,
    SegmentModule,
    SharedModule,
    NgbModule,
    NgbPopoverModule,
    PhotoModule,
    CategoryModule,
    SignatureModule,
    SignatureArrayModule,
    FormFieldsCommonModule,
    UIComponentsModule,
    ReactiveFormsModule,
    SamModule,
    ButtonComponent,
    TooltipDirective,
    BadgeComponent,
  ],
})
export class TableModule {}
