import { DialogModule } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';

import { ModalComponent } from '@site-mate/global-web-ui';

import { IModalServiceToken } from 'app/shared/interface/modal/modal.interface';

import { GlobalWebModalService } from './global-web-modal.service';

@NgModule({
  imports: [DialogModule, ModalComponent],
  providers: [
    {
      provide: IModalServiceToken,
      useClass: GlobalWebModalService,
    },
  ],
  exports: [DialogModule, ModalComponent],
})
export class GlobalWebModalModule {}
