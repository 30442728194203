import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { IUserWithSummaryOrWithoutData, PersonFieldUtils } from '@site-mate/dashpivot-shared-library';

import { SitemateUsersService } from 'app/users/sitemate-users.service';

import { PersonColumn } from './person-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'person-column.component.html',
  styleUrls: [
    '../../common/register/form-field-column/form-field-column.component.scss',
    'person-column.component.scss',
  ],
})
export class PersonColumnComponent extends FormFieldColumnComponent implements OnInit {
  @Input()
  column: PersonColumn;
  person$ = new BehaviorSubject<(IUserWithSummaryOrWithoutData & { fullName: string }) | null>(null);
  showEmptyCell = false;

  constructor(private readonly sitemateUsersService: SitemateUsersService) {
    super();
  }

  ngOnInit(): void {
    if (this.column.userIds && this.column.userIds.length > 0) {
      this.initializePerson(this.column.userIds[0]);
    } else {
      this.showEmptyCell = true;
    }
  }

  private initializePerson(userId: string) {
    const response: Map<
      string,
      Subject<IUserWithSummaryOrWithoutData>
    > = this.sitemateUsersService.getUserDataSubjects([userId]);

    response.get(userId).subscribe({
      next: (userProfile) => {
        this.person$.next({
          ...userProfile,
          fullName: PersonFieldUtils.personFieldLabel(userProfile),
        });
      },
      error: () => this.person$.next(null),
    });
  }
}
