import { Injectable } from '@angular/core';

import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

import { FormCategoryComparator } from './category/edit/form-category-comparator';
import { FormFieldComparator } from './common/edit/form-field-comparator';
import { FormDateComparator } from './date/edit/form-date-comparator';
import { FormPageBreakComparator } from './page-break/edit/form-page-break-comparator';
import { FormPersonComparator } from './person/edit/form-person-comparator';
import { FormPhotoComparator } from './photo/edit/form-photo-comparator';
import { FormSignatureComparator } from './signature/edit/form-signature-comparator';
import { FormSignatureListComparator } from './signature-array/edit/form-signature-list-comparator';
import { FormSignonTableComparator } from './signon-table/edit/form-signon-table-comparator';
import { FormSingleLineInputComparator } from './single-line-input/edit/form-single-line-input-comparator';
import { FormSketchComparator } from './sketch/edit/form-sketch-comparator';
import { FormTableComparator } from './table/edit/form-table-comparator';
import { FormYesNoComparator } from './yes-no/edit/form-yes-no-comparator';

@Injectable()
export class FormFieldComparatorFactory {
  private diffCalculatorResolver = {
    [FieldKinds.SingleLineInput]: FormSingleLineInputComparator,
    [FieldKinds.MultiLineInput]: FormSingleLineInputComparator,
    [FieldKinds.PreFilledText]: FormSingleLineInputComparator,
    [FieldKinds.SignonTable]: FormSignonTableComparator,
    [FieldKinds.Date]: FormDateComparator,
    [FieldKinds.YesNoCheckbox]: FormYesNoComparator,
    [FieldKinds.Signature]: FormSignatureComparator,
    [FieldKinds.SignatureArray]: FormSignatureListComparator,
    [FieldKinds.Table]: FormTableComparator,
    [FieldKinds.PrefilledTable]: FormTableComparator,
    [FieldKinds.Photo]: FormPhotoComparator,
    [FieldKinds.Sketch]: FormSketchComparator,
    [FieldKinds.Category]: FormCategoryComparator,
    [FieldKinds.PageBreak]: FormPageBreakComparator,
    [FieldKinds.Person]: FormPersonComparator,
  };

  getComparator(field: FieldWeb): FormFieldComparator {
    const Comparator = this.diffCalculatorResolver[field.kind];
    return new Comparator();
  }
}
