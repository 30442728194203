<cc-filter-trigger
  [filterTemplate]="dateFilterTemplate"
  (click)="toggleFilter()"
  (keydown)="toggleFilter()"
  [active]="status"
></cc-filter-trigger>

<ng-template #dateFilterTemplate>
  <form
    (mouseup)="$event.stopPropagation()"
    class="reg-view-filter-container"
    (ngSubmit)="applyFilter()"
    #filterForm="ngForm"
  >
    <div class="form-group">
      <label class="default-label form-control-label" i18n>From</label>
      <cc-date-picker [(ngModel)]="startDate" (ngModelChange)="onStartDateChange($event)" name="startDate">
      </cc-date-picker>
    </div>
    <div class="form-group">
      <label class="default-label form-control-label" i18n>To</label>
      <cc-date-picker
        [(ngModel)]="endDate"
        (ngModelChange)="onEndDateChange($event)"
        name="endDate"
      ></cc-date-picker>
    </div>
    <hr class="hr" />
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateToday"
        value="today"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsToday()"
      />
      <label class="default-label" for="createdAtDateToday"> Today </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateCurrentWeek"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsCurrentWeek()"
        value="currentWeek"
      />
      <label class="default-label" for="createdAtDateCurrentWeek"> Current Week </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateCurrentMonth"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsCurrentMonth()"
        value="currentMonth"
      />
      <label class="default-label" for="createdAtDateCurrentMonth"> Current Month </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateCurrentYear"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsCurrentYear()"
        value="currentYear"
      />
      <label class="default-label" for="createdAtDateCurrentYear"> Current Year </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateYesterday"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsYesterday()"
        value="yesterday"
      />
      <label class="default-label" for="createdAtDateYesterday"> Yesterday </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDatePast7Days"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsPast7Days()"
        value="past7days"
      />
      <label class="default-label" for="createdAtDatePast7Days"> Past 7 days </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDatePast30Days"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsPast30Days()"
        value="past30days"
      />
      <label class="default-label" for="createdAtDatePast30Days"> Past 30 days </label>
    </div>

    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateLastCalendarWeek"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsLastCalendarWeek()"
        value="lastCalendarWeek"
      />
      <label class="default-label" for="createdAtDateLastCalendarWeek"> Last calendar week </label>
    </div>

    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateLastCalendarMonth"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsLastCalendarMonth()"
        value="lastCalendarMonth"
      />
      <label class="default-label" for="createdAtDateLastCalendarMonth"> Last calendar month </label>
    </div>
    <div>
      <input
        type="radio"
        name="createdAtDate"
        id="createdAtDateLastCalendarYear"
        [(ngModel)]="radioModel"
        (click)="setCreatedAtAsLastCalendarYear()"
        value="lastCalendarYear"
      />
      <label class="default-label" for="createdAtDateLastCalendarYear"> Last calendar year </label>
    </div>
    <hr class="hr" />
    <div class="filter-button-container">
      <button
        (click)="resetFilter()"
        type="button"
        class="btn btn-link btn-sm reg-view-filter-button"
        [disabled]="!status"
      >
        <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
        <span i18n>Remove Filter</span>
      </button>
      <button type="submit" class="btn btn-link btn-sm reg-view-filter-button" [disabled]="!hasFilterData">
        <i class="cc-icon cc-icon-filter" aria-hidden="true"></i>
        <span i18n>Apply Filter</span>
      </button>
    </div>
  </form>
</ng-template>
