import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';

import { TooltipDirective } from '@site-mate/global-web-ui';

import { SignatureArrayModule } from 'app/form-fields/signature-array/signature-array.module';
import { SegmentModule } from 'app/segment/segment.module';
import { IModalServiceToken } from 'app/shared/interface/modal/modal.interface';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormSignatureDiffComponent } from './edit/diff/form-signature-diff.component';
import { FormSignatureComponent } from './edit/form-signature.component';
import { SignatureDetailsComponent } from './edit/signature-details.component';
import { SignatureColumnComponent } from './register/signature-column.component';
import { AppSignatureComponent } from './template/app-signature.component';
import { ComplementaryLogicComponent } from './template/logic-rule/signature-logic-rule/complementary-logic/complementary-logic.component';
import { PermissionTypeValuesDropdownItemComponent } from './template/logic-rule/signature-logic-rule/permission-type-values-dropdown-item/permission-type-values-dropdown-item.component';
import { RuleCounterComponent } from './template/logic-rule/signature-logic-rule/rule-counter/rule-counter.component';
import { SignatureLogicInputsComponent } from './template/logic-rule/signature-logic-rule/signature-logic-inputs/signature-logic-inputs.component';
import { SignatureLogicRuleComponent } from './template/logic-rule/signature-logic-rule/signature-logic-rule.component';
import { SignatureLogicRuleListComponent } from './template/logic-rule/signature-logic-rule-list/signature-logic-rule-list.component';
import { SignatureTemplatePreviewComponent } from './template/preview/signature-template-preview.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    SignatureColumnComponent,
    AppSignatureComponent,
    FormSignatureComponent,
    SignatureDetailsComponent,
    SignatureTemplatePreviewComponent,
    FormSignatureDiffComponent,
    SignatureLogicRuleListComponent,
    SignatureLogicRuleComponent,
    ComplementaryLogicComponent,
    SignatureLogicInputsComponent,
    PermissionTypeValuesDropdownItemComponent,
    RuleCounterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    SegmentModule,
    SignatureArrayModule,
    MomentModule,
    FormFieldsCommonModule,
    UIComponentsModule,
    TooltipDirective,
  ],
  providers: [
    {
      provide: IModalServiceToken,
      useExisting: NgbModal,
    },
  ],
  exports: [
    SignatureColumnComponent,
    AppSignatureComponent,
    FormSignatureComponent,
    SignatureTemplatePreviewComponent,
  ],
})
export class SignatureModule {}
