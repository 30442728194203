import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HeaderFooterFieldItemComponent } from './header-footer-field-item/header-footer-field-item.component';
import { HeaderFooterPrefilledTextFieldComponent } from './header-footer-field-item/header-footer-prefilled-text-field/header-footer-prefilled-text-field.component';

@NgModule({
  declarations: [HeaderFooterFieldItemComponent, HeaderFooterPrefilledTextFieldComponent],
  imports: [CommonModule, FormsModule],
})
export class HeaderFooterFieldItemModule {}
