import {
  Component,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
// eslint-disable-next-line import/no-unresolved
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

import { KeyboardInput } from 'app/shared/model/keyboard-input.enum';

import { FormulaSuggestionService } from '../../formula-suggestion/formula-suggestion.service';

@Component({
  selector: 'cc-formula-dropdown',
  templateUrl: './formula-suggestions-dropdown.component.html',
  styleUrls: ['./formula-suggestions-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormulaSuggestionsDropdownComponent {
  @Input() formula: string;
  @Output() onSelect = new EventEmitter<string>();
  @Output() updateSuggestion = new EventEmitter<string>();
  @Input() items = [];
  formulaInfo = '';
  shouldDisplayDropDown = false;
  @ViewChild('ngSelectDropdown', { read: NgSelectComponent }) ngSelectDropdown: NgSelectComponent;

  constructor(
    private readonly formulaSuggestionService: FormulaSuggestionService,
    private cdr: ChangeDetectorRef,
  ) {}

  onSelectHandler(selectedItem: string): void {
    this.shouldDisplayDropDown = false;
    const updatedFormula = this.formulaSuggestionService.getUpdatedSuggestion(this.formula, selectedItem);

    this.onSelect.emit(`${updatedFormula}(`);
  }

  ngOnChanges(): void {
    this.shouldDisplayDropDown = this.getShouldDisplayDropdown();
    if (this.shouldDisplayDropDown) {
      this.setFormulaInfo(this.items[0]);
    }
  }

  private getShouldDisplayDropdown(): boolean {
    if (!this.items.length) {
      return false;
    }

    if (this.items.length > 1) {
      return true;
    }

    const uniqueItemIsDifferentFromFormula = `=${this.items?.[0]}` !== this.formula;
    return uniqueItemIsDifferentFromFormula;
  }

  handleNavigationEvent(direction: KeyboardInput) {
    const keyInputActions: { [key in KeyboardInput]?: () => void } = {
      [KeyboardInput.Down]: () => this.itemsList.markNextItem(),
      [KeyboardInput.Up]: () => this.itemsList.markPreviousItem(),
      [KeyboardInput.Enter]: () => this.onSelectHandler(this.markedItem),
    };

    keyInputActions[direction]();
    if (direction !== KeyboardInput.Enter) {
      this.ngSelectDropdown.detectChanges();
      this.setFormulaInfo(this.markedItem);
      this.updateSuggestion.emit(this.markedItem);
    }
  }

  get markedItem(): string {
    return this.itemsList?.markedItem.value as string;
  }

  get itemsList(): ItemsList {
    return this.ngSelectDropdown?.itemsList;
  }

  onItemHover(hoveredItem: string): void {
    this.updateSuggestion.emit(hoveredItem);
    this.setFormulaInfo(hoveredItem);
  }

  private setFormulaInfo(formula: string): void {
    const { description, syntax } = this.formulaSuggestionService.getFormulaInfo(formula);
    this.formulaInfo = `${syntax} - ${description}`;
    this.cdr.detectChanges();
  }
}
