/* eslint-disable max-lines */
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Location, PlatformLocation } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  Injector,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { noop, Subject, interval, Subscription } from 'rxjs';
import { take, takeUntil, switchMap } from 'rxjs/operators';

import {
  Comment,
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
} from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { AuthService } from 'app/auth/auth.service';
import { AddEditFormBaseComponent } from 'app/form/edit-form/add-edit-form-base.component';
import { FormVersionDiffComponent } from 'app/form/form-version-diff/form-version-diff.component';
import { FormService } from 'app/form/form.service';
import { SendFormComponent } from 'app/form/send-form.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListsService } from 'app/lists/lists.service';
import { SamService } from 'app/sam/sam.service';
import { SegmentService } from 'app/segment/segment.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { FormWeb } from 'app/shared/model/form.model';
import { SurvicateSurveyTypes } from 'app/shared/model/survicate-survey-types.enum';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { FormValidationService } from 'app/shared/service/form-validation.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { MetaDataService } from 'app/shared/service/meta-data.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';

import { NewFormVersionAvailableModalComponent } from './new-form-version-available-modal/new-form-version-available-modal.component';
import { FormModalAction } from '../../apps/template-form-edit/form-modal-action.model';
import { FormEventsService } from '../form-events.service';
import { FormInstances } from '../form-instances.enum';

@Component({
  selector: 'cc-edit-form',
  templateUrl: 'edit-form.component.html',
  styleUrls: [
    'edit-form.component.scss',
    '../../../styles/legacy/app-modal.scss',
    '../form-components/form-component.scss',
  ],
})
export class EditFormComponent extends AddEditFormBaseComponent implements OnInit, OnDestroy {
  dialogRef: DialogRef<void, NewFormVersionAvailableModalComponent> | null = null;
  destroyLoadForm$ = new Subject<void>();

  teamId: string;
  activities: Comment[] = [];
  formInstance: FormInstances;
  editForm = true;
  showActions = true;
  isSSOSignUp = false;

  latestForm: FormWeb;
  checkForUpdatesInterval = 15000;
  pollingSubscription: Subscription;
  readonly abortVersionCheckRequest$ = new Subject<void>();

  constructor(
    protected readonly activeModal: NgbActiveModal,
    protected readonly appUtilService: AppUtilService,
    protected readonly toastr: ToastrService,
    protected readonly templatesService: TemplatesService,
    protected readonly confirmService: ConfirmService,
    protected readonly downloaderService: DownloaderService,
    protected readonly i18nService: TmpI18NService,
    protected readonly userService: UserService,
    protected readonly formService: FormService,
    protected readonly errorHandler: ErrorHandler,
    protected readonly metaDataService: MetaDataService,
    protected readonly modal: NgbModal,
    protected readonly listsService: ListsService,
    protected readonly location: Location,
    protected readonly platformLocation: PlatformLocation,
    protected readonly profileService: ProfileService,
    protected readonly timingMarkerService: TimingMarkerService,
    protected readonly segmentService: SegmentService,
    protected readonly formValidationService: FormValidationService,
    protected readonly logicRuleService: LogicRuleService,
    protected readonly hostElement: ElementRef,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly injector: Injector,
    protected readonly formEventsService: FormEventsService,
    protected readonly samService: SamService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly dialog: Dialog,
    private tagManagerService: GoogleTagManagerService,
    private readonly eventsService: EventsService,
    @Inject(WINDOW) protected readonly window: Window,
  ) {
    super(
      location,
      platformLocation,
      activeModal,
      i18nService,
      listsService,
      errorHandler,
      toastr,
      confirmService,
      formService,
      modal,
      metaDataService,
      profileService,
      segmentService,
      userService,
      formValidationService,
      logicRuleService,
      hostElement,
      changeDetectorRef,
      templatesService,
      injector,
      formEventsService,
      appUtilService,
      samService,
    );
  }

  ngOnInit(): void {
    void this.tagManagerService.addGtmToDom().catch(noop);
    super.ngOnInit();
    this.initializeForm();
    this.initRedirectionParams();
    this.scheduleSurvicateSurveyLaunchEvent();
  }

  ngOnDestroy() {
    this.stopPollingForNewVersion();
    super.ngOnDestroy();
    this.destroyLoadForm$.next();
    this.destroyLoadForm$.complete();
    this.abortVersionCheckRequest$.next();
    this.abortVersionCheckRequest$.complete();
  }

  private initializeForm() {
    const formInstanceOptions: Record<FormInstances, () => void> = {
      [FormInstances.AddForm]: () => this.newForm(),
      [FormInstances.EditForm]: () => this.loadForm(),
    };

    formInstanceOptions[this.formInstance]();
  }

  private newForm() {
    const currentUser = this.userService.getCurrentUser();
    this.formService.getBlankForm(this.templateId, currentUser).subscribe({
      next: (form) => {
        this.setupForm(form);
        super.setHeaderSettings(form.template);
      },
      error: this.errorHandler.handle.bind(this.errorHandler),
    });
  }

  loadForm() {
    void this.sendFormEvent(EventTypes.FormLoaded, { formId: this.formId });
    this.formService.byId(this.formId).subscribe({
      next: (form) => {
        this.latestForm = form;
        const { template } = form;
        this.templateId = template.isOrganisationTemplate ? template.organisationTemplateId : template._id;

        super.initializeHeaderSettings(this.templateId);
        this.setupForm(form);
        this.loadHistories();
        if (this.scrollTop) {
          this.changeDetectorRef.detectChanges();
          this.hostElement.nativeElement.scrollTop = this.scrollTop;
        }
        this.startPollingForNewVersion();
      },
      error: (error) => {
        if (error.status && error.status === 404) {
          this.toastr.errorByKey('formNotFound');
        } else {
          this.errorHandler.handle(error);
        }
      },
    });
  }

  private loadHistories() {
    this.formService
      .getHistories(this.form._id)
      .pipe(take(1))
      .subscribe((res) => {
        this.activities = res.reverse().map((activity) => {
          if (activity.createdBy) {
            activity.initial = UserService.getInitial(
              activity.createdBy.firstName,
              activity.createdBy.lastName,
            );
            activity.initialStyle = UserService.getInitialStyle(activity.initial);
          }
          return activity;
        });
      });
  }

  openNewFormVersionAvailableModal() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(NewFormVersionAvailableModalComponent, {});
      this.dialogRef.componentInstance.onKeepChanges.subscribe(() => {
        this.destroyLoadForm$.next();
      });
      this.dialogRef.componentInstance.onUpdate.subscribe(() => {
        this.setupForm(this.latestForm);
        this.loadHistories();
        super.initializeHeaderSettings(this.templateId);
        this.dialogRef.close();
        this.dialogRef = null;
      });
    }
  }

  isFormValidated(): boolean {
    if (!this.form || !this.isValid(this.navigateOnSignUpToFormModal)) {
      this.toastr.error(this.i18nService.getMessage('invalidForm'));
      return false;
    }
    return true;
  }

  async onSave() {
    await this.saveForm(!this.isSSOSignUp)
      .then((saved) => {
        if (saved) {
          this.postSave();
        }
      })
      .catch((err: unknown) => {
        this.errorHandler.handle(err);
      });
  }

  postSave() {
    const onSaveOptions: Record<FormInstances, () => void> = {
      [FormInstances.AddForm]: () => this.addFormOnSave(),
      [FormInstances.EditForm]: () => this.editFormOnSave(),
    };
    onSaveOptions[this.formInstance]();
  }

  customizeTemplate() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.CtaClicked, { Context: 'Customise This $FormName' }),
      this.segmentService,
    );
    this.activeModal.dismiss({
      reason: FormModalAction.Customise,
    });
  }

  private addFormOnSave(): void {
    this.cleanUpBeforeClose();
    this.activeModal.close({ reason: FormModalAction.Add, formId: this.form._id });

    if (this.isSSOSignUp) {
      const customRedirectUrl = `apps/${this.templateId}/forms/timeline`;
      const message = this.i18nService.getMessage('ssoRedirectionMessage');
      this.authService.setCustomAuthRedirectURL(customRedirectUrl);
      this.authService.showRedirectionModal(message);
    }
  }

  private editFormOnSave(): void {
    this.cleanUpBeforeClose();
    this.activeModal.close({ reason: FormModalAction.Save, formId: this.form._id });
  }

  remove() {
    this.confirmService
      .confirmDelete('deleteForm')
      .then(() => {
        return this.formService.remove(this.form._id).subscribe({
          next: () => {
            this.toastr.successByKey('formDeleted');
            this.cleanUpBeforeClose();
            this.activeModal.close({ reason: FormModalAction.Delete, formId: this.form._id });
          },
          error: (error) => {
            this.errorHandler.handle(error);
          },
        });
      })
      .catch(() => {
        // no-op: confirmation cancelled
      });
  }

  async clone() {
    if (!this.form || this.isLibraryView) {
      return;
    }

    if (this.form._id && !this.hasFormBeenChanged()) {
      this.cloneForm();
      return;
    }

    try {
      await this.confirmService.confirm('cloneForm', {
        confirmButtonText: this.i18nService.getMessage('confirmSaveAndClone'),
        cancelButtonText: this.i18nService.getMessage('confirmCancel'),
      });

      this.isCloning = true;
      const saved = await this.saveForm();
      if (saved) {
        this.cloneForm();
      }
      this.isCloning = false;
    } catch (error) {
      // no-op: confirmation cancelled
    }
  }

  cloneForm() {
    this.isCloning = true;
    this.formService.clone(this.form._id).subscribe({
      next: (cloned) => {
        this.toastr.successByKey('formCloned');
        this.isCloning = false;
        this.cleanUpBeforeClose();
        this.activeModal.close({
          reason: FormModalAction.Clone,
          formId: cloned._id,
        });
      },
      error: (error) => {
        this.isCloning = false;
        this.errorHandler.handle(error);
      },
    });
  }

  private sendFormEvent(eventType: EventTypes, params: Record<string, string>) {
    return EventNotifierService.notify(new DashpivotEvent(eventType, params), this.segmentService);
  }

  private getUrlParams() {
    return {
      id: JSON.stringify([this.form._id]),
    };
  }

  async saveForm(showToastr: boolean = true, skipValidation: boolean = false): Promise<boolean> {
    this.stopPollingForNewVersion();
    return super.saveForm(showToastr, skipValidation).then((saved) => {
      if (this.form._id) {
        this.startPollingForNewVersion();
      }
      return saved;
    });
  }

  downloadForm() {
    const url = this.downloaderService.getDownloadSingleFormUrl(this.form);
    this.window.open(`${url}`, '_blank');
  }

  async download() {
    if (this.isLibraryView || !this.form) {
      return;
    }

    if (this.form._id && !this.hasFormBeenChanged()) {
      this.downloadForm();
      return;
    }

    try {
      await this.confirmService.confirm('downloadForm', {
        confirmButtonText: this.i18nService.getMessage('confirmSaveAndDownload'),
        cancelButtonText: this.i18nService.getMessage('confirmCancel'),
      });

      const saved = await this.saveForm();
      if (saved) {
        this.downloadForm();
      }
    } catch (error) {
      // no-op: confirmation cancelled
    }
  }

  onClose() {
    const onCloseOptions: Record<FormInstances, () => void> = {
      [FormInstances.AddForm]: () => this.addFormOnClose(),
      [FormInstances.EditForm]: () => this.activeModal.dismiss(),
    };
    onCloseOptions[this.formInstance]();
  }

  private addFormOnClose(): void {
    if (this.hasFormBeenSaved) {
      this.cleanUpBeforeClose();
      this.activeModal.close({
        reason: FormModalAction.Add,
        formId: this.form?._id,
      });
    } else {
      this.activeModal.dismiss();
    }
  }

  async beforeDismiss(): Promise<boolean> {
    const beforeDismissOptions: Record<FormInstances, () => Promise<boolean>> = {
      [FormInstances.AddForm]: () => this.addFormBeforeDismiss(),
      [FormInstances.EditForm]: () => this.editFormBeforeDismiss(),
    };

    const dismissResult = await beforeDismissOptions[this.formInstance]();
    if (dismissResult) {
      this.cleanUpBeforeClose();
    }
    return dismissResult;
  }

  async editFormBeforeDismiss() {
    if (this.isLibraryView || !(this.form && this.form.items)) {
      return true;
    }

    if (this.hasFormBeenChanged()) {
      return this.editConfirmAndSave();
    }

    return !this.closingFromFinalWorkflowColumn();
  }

  async addFormBeforeDismiss(): Promise<boolean> {
    if (!this.form || !this.form.items) {
      return true;
    }

    if (this.hasFormBeenChanged() && !this.navigateOnSignUpToFormModal) {
      return this.addConfirmAndSave();
    }

    return true;
  }

  private async addConfirmAndSave(): Promise<boolean> {
    try {
      await this.confirmService.confirmSave('saveChangeBeforeClose');
      await this.onSave();
      return false; // this.onSave will close the modal
    } catch (dismissConfirm) {
      if (dismissConfirm === 'close') {
        return false;
      }

      return true;
    }
  }

  private async editConfirmAndSave(): Promise<boolean> {
    try {
      await this.confirmService.confirmSave('saveChangeBeforeCloseForm');
      await this.onSave();
      return false; // this.onSave will close the modal
    } catch (dismiss) {
      if (dismiss === 'cancel') {
        return !this.closingFromFinalWorkflowColumn();
      }
    }

    return false;
  }

  private closingFromFinalWorkflowColumn(): boolean {
    if (
      this.formValidationService.isWorkflow(this.form) &&
      this.formValidationService.isInLastColumn(this.form)
    ) {
      if (!this.isValid()) {
        this.toastr.error(this.i18nService.getMessage('closingInvalidFormInLastColumn'));
        return true;
      }
    }
    return false;
  }

  showVersionDiff(activity) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormActionApplied, { Context: 'Viewed History' }),
      this.segmentService,
    );
    const modalRef = this.modal.open(FormVersionDiffComponent, {
      windowClass: 'modal-with-margin',
    });
    modalRef.componentInstance.activity = activity;
    modalRef.componentInstance.formId = this.form._id;
    modalRef.componentInstance.formVersion = this.form.formVersion;
    modalRef.result.catch(() => {
      // no-op
    });
  }

  async onSend() {
    if (this.isLibraryView || !this.form) {
      return;
    }

    if (this.form._id && !this.hasFormBeenChanged()) {
      this.sendForm();
      return;
    }

    await this.confirmService
      .confirm('sendForm', {
        confirmButtonText: this.i18nService.getMessage('confirmSaveAndSend'),
        cancelButtonText: this.i18nService.getMessage('confirmCancel'),
      })
      .then(async () => {
        const saved = await this.saveForm();
        if (saved) {
          this.sendForm();
        }
      })
      .catch(() => {
        // no-op: confirmation cancelled
      });
  }

  sendForm() {
    const [templateId] = this.form.parents;
    const modalRef = this.modal.open(SendFormComponent);
    modalRef.componentInstance.formId = this.form._id;
    modalRef.componentInstance.templateId = templateId;
    modalRef.componentInstance.params = this.getUrlParams();
    modalRef.result.catch(() => {
      this.loadHistories();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  confirm(providedEvent) {
    if (this.form && this.hasFormBeenChanged()) {
      providedEvent.returnValue = true;
    }
  }

  get canDelete(): boolean {
    return (
      this.formInstance === FormInstances.AddForm ||
      this.userService.isTeamControllerOrAbove() ||
      this.userService.isCreatedByCurrentUser(this.form)
    );
  }

  private initRedirectionParams() {
    this.route.queryParams.pipe(takeUntil(this.onDestroySub)).subscribe((params: Params) => {
      this.navigateOnSignUpToFormModal = params?.navigateOnSignUpToFormModal === 'true';
      this.teamId = params.teamId;
      this.isSSOSignUp = params.isSSOSignUp === 'true';
    });
  }

  stopPollingForNewVersion() {
    this.pollingSubscription?.unsubscribe();
    this.abortVersionCheckRequest$.next();
  }

  startPollingForNewVersion() {
    this.stopPollingForNewVersion();
    this.pollingSubscription = interval(this.checkForUpdatesInterval)
      .pipe(
        takeUntil(this.destroyLoadForm$),
        switchMap(() => this.formService.byId(this.formId).pipe(takeUntil(this.abortVersionCheckRequest$))),
      )
      .subscribe({
        next: (form) => {
          this.latestForm = form;
          if (this.form?.formVersion !== form.formVersion && !this.saving) {
            this.openNewFormVersionAvailableModal();
          }
        },
        error: (error) => this.errorHandler.handle(error),
      });
  }

  private scheduleSurvicateSurveyLaunchEvent() {
    if (this.navigateOnSignUpToFormModal) {
      setTimeout(() => {
        this.eventsService.trackEvent(EventTypes.LaunchSurvicateSurvey, {
          Context: `dpw-7326-${SurvicateSurveyTypes.TemplateReferralFeedback}`,
          templateId: this.templateId,
        });
      }, 5000);
    }
  }
}
