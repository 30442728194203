import { values, without } from 'lodash-es';

import { CategorySourceTypes } from '@site-mate/dashpivot-shared-library';

import { CategoryHeaderColumn } from './category-header-column';
import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class CategoryColumn extends FormFieldColumn {
  selectedItems: any[];
  availableItems: any[];
  referenceListId: string;
  isMultipleSelection: boolean;
  listSource: CategorySourceTypes;

  constructor(item, headerColumn: CategoryHeaderColumn, renderer) {
    super(item, renderer);

    this.availableItems = headerColumn.availableItems;
    this.referenceListId = headerColumn.referenceListId;

    this.isMultipleSelection = item.categoryType === 'dropdown';
    this.selectedItems = item.selectedItems || [];
    this.listSource = item.listSource || item.categorySource;

    if (!Array.isArray(this.selectedItems)) {
      this.selectedItems = values(this.selectedItems);
    }

    this.selectedItems = without(this.selectedItems, [null, undefined]);
  }
}
