import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { CreatedDateThumbnailComponent } from './thumbnail/created-date-thumbnail.component';

@NgModule({
  imports: [CommonModule, MomentModule],
  declarations: [CreatedDateThumbnailComponent],
})
export class CreatedDateModule {}
