<div class="modal-header">
  <h4 class="modal-title sm-text-lg">
    <span i18n>Showing changes:</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="display-flex sm-relative sm-p-4">
  <div *ngIf="!loading; else loadingBlock">
    <div class="form-diff-row sm-gap-6">
      <div class="form-diff-col">
        <div>
          <h5 class="version-status" i18n>Before</h5>
        </div>

        <div *ngIf="previousFormVersion?.formVersion; else notAvailable" class="sm-w-fit">
          <div><span class="sm-font-bold">Form Version:</span> {{ previousFormVersion?.formVersion }}</div>
          <div>
            <span class="sm-font-bold">Date:</span>
            {{ previousFormVersion?.updatedAt | amDateFormat: 'lll' }}
          </div>
        </div>
      </div>
      <div class="form-diff-col">
        <div>
          <h5 class="version-status" i18n>After</h5>
        </div>
        <div><span class="sm-font-bold">Form Version:</span> {{ nextFormVersion?.formVersion }}</div>
        <div>
          <span class="sm-font-bold">Date:</span>
          {{ nextFormVersion?.updatedAt | amDateFormat: 'lll' }}
        </div>
      </div>
    </div>
    <div class="form-diff-row">
      <div class="form-diff-col">
        <hr class="hr" />
      </div>
    </div>

    <ng-container *ngFor="let item of nextFormVersion.items; let i = index">
      <cc-form-field-version-diff
        class="form-diff-row"
        *ngIf="diff.get(item.id)"
        [previous]="previousFormVersion.items[i]"
        [next]="nextFormVersion.items[i]"
        [isLandscapeMode]="isLandscapeMode"
      ></cc-form-field-version-diff>
    </ng-container>

    <div class="form-diff-row attachments-diff" *ngFor="let item of attachmentDiffs">
      <div class="form-diff-col">
        <div class="form-diff-row sm-bg-grey-1 sm-py-1 sm-text-center sm-text-default-text">
          <div class="form-diff-col">
            <strong>Form Attachments</strong>
          </div>
        </div>
        <div class="items form-diff-row items sm-py-1">
          <div class="form-diff-col column">
            <div class="sm-my-2 sm-whitespace-nowrap" *ngFor="let attachment of item.prevAttachments">
              <a
                [href]="attachment.urlDownload"
                target="_blank"
                rel="noopener noreferrer"
                class="sm-my-1 sm-text-default-text"
              >
                <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>{{ attachment.filename }}</a
              >
            </div>
          </div>
          <div class="form-diff-col column">
            <div class="sm-my-2 sm-whitespace-nowrap" *ngFor="let attachment of item.currentAttachments">
              <a
                [href]="attachment.urlDownload"
                target="_blank"
                rel="noopener noreferrer"
                class="sm-my-1 sm-text-default-text"
              >
                <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>{{ attachment.filename }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notAvailable>
  <div class="sm-text-center" i18>Not available.</div>
</ng-template>

<ng-template #loadingBlock>
  <div class="loading-spinner" *ngIf="loading">
    <div class="cc-icon cc-icon-spinner-3x"></div>
  </div>
</ng-template>
