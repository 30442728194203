import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class SignatureColumn extends FormFieldColumn {
  signatureUrl: string;
  fullName: string;
  signedOn: string;

  constructor(item, renderer) {
    super(item, renderer);
    this.signatureUrl = item.signatureUrl;
    this.fullName = item.fullName;
    this.signedOn = item.signedOn;
  }
}
