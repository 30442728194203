import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { ButtonComponent } from '@site-mate/global-web-ui';

import { SegmentModule } from 'app/segment/segment.module';
import { GlobalWebModalModule } from 'app/shared/service/modal/global-web-modal.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormSignonTableDiffComponent } from './edit/diff/form-signon-table-diff.component';
import { SignonTableEditComponent } from './edit/signon-table-edit.component';
import { MapModalComponent } from './location/map-modal/map-modal.component';
import { SignonTableLocationComponent } from './location/signon-table-location.component';
import { LocationColumnComponent } from './register/location-column/location-column.component';
import { UserColumnHeaderComponent } from './register/user-column/user-column-header.component';
import { UserColumnComponent } from './register/user-column/user-column.component';
import { SignonTableTemplatePreviewComponent } from './template/preview/signon-table-template-preview.component';
import { SignonTableTemplateComponent } from './template/signon-table-template.component';
import { SignonTableUserComponent } from './user/signon-table-user.component';
import { FormFieldsCommonModule } from '../common/common.module';
import { TableModule } from '../table/table.module';

@NgModule({
  declarations: [
    SignonTableTemplateComponent,
    SignonTableEditComponent,
    SignonTableUserComponent,
    SignonTableLocationComponent,
    LocationColumnComponent,
    UserColumnComponent,
    UserColumnHeaderComponent,
    SignonTableTemplatePreviewComponent,
    FormSignonTableDiffComponent,
    MapModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SegmentModule,
    TableModule,
    GoogleMapsModule,
    NgSelectModule,
    FormFieldsCommonModule,
    UIComponentsModule,
    GlobalWebModalModule,
    ButtonComponent,
  ],
  exports: [SignonTableTemplateComponent, SignonTableEditComponent, SignonTableTemplatePreviewComponent],
})
export class SignonTableModule {}
