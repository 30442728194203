import { Injectable } from '@angular/core';

import { FormUtil } from './form-util.service';
import { HttpClientService } from '../shared/service/http-client.service';

@Injectable()
export class LibraryFormService {
  constructor(private http: HttpClientService) {}

  byId(formId) {
    return this.http.get(`template-libraries/forms/${formId}`);
  }

  getLibraryFormHistories(formId) {
    return this.http.get(`template-libraries/forms/${formId}/comments`);
  }

  getLibraryFormItemValues(appId, itemId) {
    return this.http.get(`template-libraries/${appId}/forms/items/${itemId}/values`);
  }

  getLibraryTemplateRegisterView(appId, limit, offset, criteria: Record<string, any>[]) {
    const url = `template-libraries/${appId}/register-view/?limit=${limit}&offset=${offset}${FormUtil.getRegisterViewSearchStr(
      criteria,
    )}`;
    return this.http.get(url);
  }

  listByLibraryTemplateIdPagination(appId, limit, offset, criteria) {
    let url;
    if (criteria) {
      url = `template-libraries/${appId}/forms/filter?limit=${limit}&offset=${offset}`;
      Object.keys(criteria).forEach((k) => {
        url += `&${k}=${criteria[k]}`;
      });
    } else {
      url = `template-libraries/${appId}/forms?limit=${limit}&offset=${offset}`;
    }
    return this.http.get(url);
  }

  byVersion(formId, version) {
    return this.http.get(`template-libraries/forms/${formId}/versions/${version}`);
  }
}
