import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';

import { CreatedByThumbnailComponent } from './thumbnail/created-by-thumbnail.component';

@NgModule({
  imports: [SharedModule, CommonModule],
  declarations: [CreatedByThumbnailComponent],
})
export class CreatedByModule {}
