import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipDirective } from '@site-mate/global-web-ui';

import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { AskSamToImportComponent } from './ask-sam-to-import/ask-sam-to-import.component';
import { SamBannerComponent } from './sam-banner/sam-banner.component';
import { SamImportModalComponent } from './sam-import-modal/sam-import-modal.component';
import { SamService } from './sam.service';

@NgModule({
  imports: [CommonModule, TooltipDirective, UIComponentsModule],
  exports: [AskSamToImportComponent, SamBannerComponent],
  declarations: [AskSamToImportComponent, SamBannerComponent, SamImportModalComponent],
  providers: [SamService],
})
export class SamModule {}
