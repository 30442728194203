import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, combineLatest } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { FormAttachment, Template, TemplateOrientations } from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { FormFieldComparatorFactory } from 'app/form-fields/form-field-comparator-factory';

import { appControlType } from '../../apps/app-control-type.model';
import { FormService } from '../form.service';

@Component({
  selector: 'cc-form-version-diff',
  templateUrl: 'form-version-diff.component.html',
  styleUrls: ['form-version-diff.component.scss'],
})
export class FormVersionDiffComponent implements OnInit {
  controlType = appControlType;

  @Input() activity;
  @Input() formId: string;
  @Input() formVersion: number;

  loading: boolean;
  defaultFormat = {
    items: [],
    dynamicAttachments: [],
    updatedAt: '',
    createdAt: '',
    createdBy: { fullName: '' },
  };

  previousFormVersion: Template;
  nextFormVersion: Template;

  attachmentDiffs: Array<{ prevAttachments: FormAttachment[]; currentAttachments: FormAttachment[] }> = [];

  diff = new Map<string, boolean>();

  isLandscapeMode: boolean;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly formService: FormService,
    private readonly formFieldComparatorFactory: FormFieldComparatorFactory,
    private readonly templateService: TemplatesService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.fetchAndSetVersions();
  }

  fetchAndSetVersions() {
    const { formVersionBeforeUpdate, formVersionAfterUpdate } = this.activity;

    const templates$ = [formVersionBeforeUpdate, formVersionAfterUpdate].map((version) =>
      version === 0
        ? of(this.defaultFormat)
        : this.formService.byVersion(this.formId, version).pipe(catchError(() => of(this.defaultFormat))),
    );

    combineLatest(templates$).subscribe(([prev, next]) => {
      this.previousFormVersion = prev;
      this.nextFormVersion = next;
      this.calculateDifferences();
      this.setIsLandscapeMode();
    });
  }

  close() {
    this.modal.dismiss();
  }

  calculateDifferences() {
    this.previousFormVersion.items.forEach((item, i) => {
      const diffCalculator = this.formFieldComparatorFactory.getComparator(item) ?? null;

      if (!diffCalculator) {
        this.diff.set(item.id, false);
        return;
      }

      const isDifferent = !diffCalculator.isEqual(
        this.previousFormVersion.items[i],
        this.nextFormVersion.items[i],
      );
      this.diff.set(item.id, isDifferent);
    });

    this.diffAttachment(this.previousFormVersion.dynamicAttachments, this.nextFormVersion.dynamicAttachments);
  }

  diffAttachment(prevAttachments: FormAttachment[] = [], currentAttachments: FormAttachment[] = []) {
    const prevAttachmentsIds = prevAttachments.map(({ _id }) => _id);
    const currentAttachmentsIds = currentAttachments.map(({ _id }) => _id);

    if (JSON.stringify(prevAttachmentsIds) !== JSON.stringify(currentAttachmentsIds)) {
      this.attachmentDiffs.push({ prevAttachments, currentAttachments });
    }
  }

  setIsLandscapeMode() {
    if (!this.nextFormVersion?.parents?.length) {
      this.loading = false;
      this.isLandscapeMode = false;
      return;
    }
    this.templateService
      .getApp(this.nextFormVersion.parents[0])
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        (template) => {
          this.isLandscapeMode = template?.orientation === TemplateOrientations.Landscape;
        },
        () => {
          this.isLandscapeMode = false;
        },
      );
  }
}
