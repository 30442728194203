import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbAlertModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MomentModule } from 'ngx-moment';

import { FormSignatureService } from '@site-mate/dashpivot-shared-library';
import { ButtonComponent, ModalComponent } from '@site-mate/global-web-ui';

import { FormFieldsModule } from 'app/form-fields/form-fields.module';
import { PhotoModule } from 'app/photo/photo.module';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { EditFormComponent } from './edit-form/edit-form.component';
import { FormAvailableOnMobileAlertComponent } from './edit-form/form-available-on-mobile-alert/form-available-on-mobile-alert.component';
import { AuthorDataComponent } from './edit-form/form-header/author-data/author-data.component';
import { FormHeaderComponent } from './edit-form/form-header/form-header.component';
import { FormSignUpToDownloadModalComponent } from './edit-form/form-sign-up-to-download-modal/form-sign-up-to-download-modal.component';
import { NewFormVersionAvailableModalComponent } from './edit-form/new-form-version-available-modal/new-form-version-available-modal.component';
import { PreviewFormComponent } from './edit-form/preview-form/preview-form.component';
import { ResetWorkflowComponent } from './edit-form/reset-workflow/reset-workflow.component';
import { EditFormFieldComponent } from './edit-form-field/edit-form-field.component';
import { FormManualSignatureComponent } from './form-components/form-manual-signature.component';
import { FormPdfPreviewComponent } from './form-components/form-pdf-preview/form-pdf-preview.component';
import { FormPhotoModalComponent } from './form-components/form-photo-modal.component';
import { FormStaticAttachmentsComponent } from './form-components/form-static-attachments.component';
import { FormDateUtil } from './form-date-util.service';
import { FormEventsService } from './form-events.service';
import { FormModalToolBarComponent } from './form-modal-tool-bar.component';
import { FormUtil } from './form-util.service';
import { FormVersionDiffComponent } from './form-version-diff/form-version-diff.component';
import { FormService } from './form.service';
import { LibraryFormService } from './library-form.service';
import { SendFormComponent } from './send-form.component';
import { FormAttachmentComponent } from '../form-fields/attachment/edit/form-attachment.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    SharedModule,
    PhotoModule,
    FormFieldsModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgSelectModule,
    NgbAlertModule,
    VirtualScrollerModule,
    GoogleTagManagerModule,
    UIComponentsModule,
    ModalComponent,
    ButtonComponent,
  ],
  declarations: [
    EditFormComponent,
    PreviewFormComponent,
    FormAttachmentComponent,
    FormStaticAttachmentsComponent,
    FormModalToolBarComponent,
    FormVersionDiffComponent,
    SendFormComponent,
    FormManualSignatureComponent,
    FormPhotoModalComponent,
    FormPdfPreviewComponent,
    EditFormFieldComponent,
    FormAvailableOnMobileAlertComponent,
    FormHeaderComponent,
    AuthorDataComponent,
    ResetWorkflowComponent,
    FormSignUpToDownloadModalComponent,
    NewFormVersionAvailableModalComponent,
  ],
  exports: [PreviewFormComponent, EditFormFieldComponent],
  providers: [
    FormService,
    FormUtil,
    FormDateUtil,
    LibraryFormService,
    LogicRuleService,
    FormEventsService,
    FormSignatureService,
  ],
})
export class FormModule {}
