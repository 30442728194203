import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class AttachmentColumn extends FormFieldColumn {
  attachments: any[];

  constructor(item, renderer) {
    super(item, renderer);

    this.attachments = item.attachments;
  }
}
