import { Component, Input } from '@angular/core';

import { DateColumn } from './date-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'date-column.component.html',
  styleUrls: [
    '../../common/register/form-field-column/form-field-column.component.scss',
    'date-column.component.scss',
  ],
})
export class DateColumnComponent extends FormFieldColumnComponent {
  @Input()
  column: DateColumn;
}
