import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { CategoryAnalyticsFilterComponent } from './analytics/category-analytics-filter.component';
import { FormCategoryDiffComponent } from './edit/diff/form-category-diff.component';
import { FormCategoryComponent } from './edit/form-category.component';
import { CategoryColumnComponent } from './register/category-column.component';
import { AppCategoryItemComponent } from './template/app-category-item.component';
import { CategoryTemplateComponent } from './template/category-template.component';
import { CategoryTemplatePreviewComponent } from './template/preview/category-template-preview.component';
import { CategoryThumbnailComponent } from './thumbnail/category-thumbnail.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    SharedModule,
    SegmentModule,
    FormFieldsCommonModule,
    UIComponentsModule,
  ],
  declarations: [
    CategoryColumnComponent,
    CategoryThumbnailComponent,
    FormCategoryComponent,
    CategoryTemplatePreviewComponent,
    AppCategoryItemComponent,
    CategoryTemplateComponent,
    CategoryAnalyticsFilterComponent,
    FormCategoryDiffComponent,
  ],
  exports: [
    CategoryColumnComponent,
    FormCategoryComponent,
    CategoryTemplatePreviewComponent,
    CategoryTemplateComponent,
    AppCategoryItemComponent,
  ],
})
export class CategoryModule {}
