import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';

import { UserColumnComponent } from './register/user-column.component';

@NgModule({
  declarations: [UserColumnComponent],
  imports: [CommonModule, SharedModule],
  exports: [UserColumnComponent],
})
export class UserModule {}
