<div class="column" [class.column-sm]="column.static">
  <div *ngIf="column.signatureUrl; else notSigned">
    <div class="signature" [class.column-sm]="column.static">
      <img [src]="column.signatureUrl" [alt]="column.fullName" class="signature-image" />
    </div>
    <div class="text-muted">
      <small>{{ column.fullName }}</small>
    </div>
    <div class="text-muted">
      <small>
        {{ column.signedOn | amDateFormat: 'lll' }}
      </small>
    </div>
  </div>
</div>

<ng-template #notSigned>
  <div class="text-muted" i18n>-</div>
</ng-template>
