import moment from 'moment-timezone';

export class NgbDateUtil {
  static toObject(momentObj) {
    return { year: momentObj.years, month: momentObj.months + 1, day: momentObj.date };
  }

  static toMoment(obj) {
    return moment({ year: obj.year, month: obj.month - 1, day: obj.day });
  }
}
