import { Component, Input } from '@angular/core';

import { SignatureColumn } from './signature-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'signature-column.component.html',
  styleUrls: [
    '../../common/register/form-field-column/form-field-column.component.scss',
    '../../signature-array/edit/form-signature-list.component.scss',
  ],
})
export class SignatureColumnComponent extends FormFieldColumnComponent {
  @Input()
  column: SignatureColumn;
}
