import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SegmentModule } from 'app/segment/segment.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormYesNoDiffComponent } from './edit/diff/form-yes-no-diff.component';
import { YesNoEditComponent } from './edit/yes-no-edit.component';
import { YesNoColumnComponent } from './register/yes-no-column.component';
import { YesNoTemplatePreviewComponent } from './template/preview/yes-no-template-preview.component';
import { YesNoButtonTemplateComponent } from './template/yes-no-button-template.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    YesNoEditComponent,
    YesNoColumnComponent,
    YesNoTemplatePreviewComponent,
    YesNoButtonTemplateComponent,
    FormYesNoDiffComponent,
  ],
  imports: [CommonModule, FormFieldsCommonModule, SegmentModule, FormsModule, NgbModule, UIComponentsModule],
  exports: [
    YesNoEditComponent,
    YesNoColumnComponent,
    YesNoTemplatePreviewComponent,
    YesNoButtonTemplateComponent,
  ],
})
export class YesNoModule {}
