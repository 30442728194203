import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';

import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { PhotoContainerComponent } from './photo-container.component';
import { PhotoModalSideToolComponent } from './photo-modal-side-tool.component';
import { PhotoModalToolBarComponent } from './photo-modal-tool-bar.component';
import { PhotoModalComponent } from './photo-modal.component';
import { PhotoToolBarComponent } from './photo-tool-bar.component';
import { PhotoComponent } from './photo.component';
import { TagFilterComponent } from './tag-filter.component';
import { TeamSelectComponent } from './team-select.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    NgbPopoverModule,
    NgSelectModule,
    InfiniteScrollModule,
    LazyLoadImageModule,
    GoogleMapsModule,
    RouterModule,
    SharedModule,
    UIComponentsModule,
  ],
  declarations: [
    PhotoComponent,
    PhotoToolBarComponent,
    PhotoModalComponent,
    TagFilterComponent,
    PhotoContainerComponent,
    PhotoModalToolBarComponent,
    PhotoModalSideToolComponent,
    TeamSelectComponent,
  ],
  exports: [PhotoComponent],
})
export class PhotoModule {}
