import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cc-new-form-version-available-modal',
  templateUrl: './new-form-version-available-modal.component.html',
})
export class NewFormVersionAvailableModalComponent {
  @Output() onKeepChanges = new EventEmitter<void>();
  @Output() onUpdate = new EventEmitter<void>();

  constructor(public dialogRef: DialogRef<void>) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  handleKeepChanges() {
    this.onKeepChanges.emit();
    this.dialogRef.close();
  }
}
