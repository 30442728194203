import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CounterThumbnailComponent } from './thumbnail/counter-thumbnail.component';

@NgModule({
  declarations: [CounterThumbnailComponent],
  imports: [CommonModule],
})
export class CounterModule {}
