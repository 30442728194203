export const formulaExamples = [
  {
    label: 'Time calculation',
    link: 'https://intercom.help/dashpivot/en/articles/3695027-dashpivot-formulas#date-and-time-calculations',
    syntax: 'Calculate the hours between two time cells:',
    formula: '=TIMEDIF(A1,B1)',
  },
  {
    label: 'Overtime calculation',
    link: 'https://intercom.help/dashpivot/en/articles/3695027-dashpivot-formulas#h_f56adebb13',
    syntax: 'Calculate overtime above 8 hours:',
    formula: '=IF(TIMEDIF(A1,B1)-C1>8,TIMEDIF(A1,B1)-C1-8,0)',
  },
  {
    label: 'Daily cost calculation',
    link: 'https://intercom.help/dashpivot/en/articles/3695027-dashpivot-formulas#h_790834efed',
    syntax: 'Sum columns in multiple tables:',
    formula: '=SUM(Table1!A:A)+SUM(Table2!A:A)',
  },
  {
    label: 'Inspection score calculation',
    link: 'https://intercom.help/dashpivot/en/articles/3695027-dashpivot-formulas#h_42849b8b41',
    syntax: 'Display ‘PASS’ if score is above 90:',
    formula: '=IF(A1>90,"PASS","FAIL")',
  },
];
