import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

import { IModalRef, IModalService } from '../../interface/modal/modal.interface';

@Injectable()
export class GlobalWebModalService
  implements IModalService<unknown, ComponentType<unknown>, unknown, unknown, unknown>
{
  constructor(private readonly dialog: Dialog) {}

  open(content: ComponentType<unknown>, options?: unknown): IModalRef {
    const dialogRef = this.dialog.open(content, options);
    return this.convertToModalRef(dialogRef);
  }

  dismissAll(): void {
    this.dialog.closeAll();
  }

  private convertToModalRef(dialogRef: DialogRef): IModalRef<unknown, unknown, unknown> {
    return {
      componentInstance: dialogRef.componentInstance,
      close: (result) => dialogRef.close(result),
      dismiss: (reason) => dialogRef.close(reason),
    };
  }
}
