<gw-modal [dismissable]="false">
  <div class="sm-flex sm-w-100 sm-flex-col sm-items-center sm-gap-y-4 sm-text-center sm-text-default-text">
    <i class="cc-icon cc-icon-warning !sm-text-8xl sm-text-default-orange"></i>
    <h1 i18n class="sm-text-xl sm-font-medium">New form version available</h1>
    <div i18n>
      A newer version of this form is available. Would you like to keep your changes or update to the latest
      version?
    </div>
    <div i18n>
      Note: If you keep your changes, they will overwrite changes made elsewhere. This check is conducted
      every 15 seconds to ensure you have the most up-to-date version.
    </div>
    <div class="sm-flex sm-items-center sm-gap-x-2">
      <button
        gw-button
        type="button"
        color="grey-2"
        text="Keep my changes"
        (click)="handleKeepChanges()"
        data-test="keep-changes-button"
      ></button>
      <button
        gw-button
        type="button"
        color="green"
        text="Update to latest version"
        (click)="onUpdate.emit()"
        data-test="update-button"
      ></button>
    </div>
  </div>
</gw-modal>
