import { User } from '@site-mate/dashpivot-shared-library';

import { FormFieldColumn } from '../../common/register/form-field-column/form-field-column';

export class UserColumn extends FormFieldColumn {
  user: User;

  constructor(item, renderer) {
    super(item, renderer);
    this.user = item.user;
  }
}
