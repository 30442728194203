import { Component, Input } from '@angular/core';

import { SignatureArrayColumn } from './signature-array-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'signature-array-column.component.html',
  styleUrls: ['../../common/register/form-field-column/form-field-column.component.scss'],
})
export class SignatureArrayColumnComponent extends FormFieldColumnComponent {
  @Input()
  column: SignatureArrayColumn;
}
