import { Component, Input } from '@angular/core';

import { UserColumn } from './user-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'user-column.component.html',
  styleUrls: [
    '../../common/register/form-field-column/form-field-column.component.scss',
    'user-column.component.scss',
  ],
})
export class UserColumnComponent extends FormFieldColumnComponent {
  @Input()
  column: UserColumn;
}
