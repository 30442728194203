import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DashpivotEvent, EventTypes } from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-send-form',
  templateUrl: 'send-form.component.html',
})
export class SendFormComponent {
  itemName;
  emails: string;
  regex = this.appUtilService.emailRegex;
  working;
  invalidMails = [];
  invalid;
  formId;
  templateId;
  params;

  constructor(
    private readonly appUtilService: AppUtilService,
    public readonly modal: NgbActiveModal,
    private readonly formService: FormService,
    private readonly toastr: ToastrService,
    private readonly errorHandler: ErrorHandler,
    private readonly timingMarkerService: TimingMarkerService,
  ) {}

  onSubmit() {
    this.working = true;
    this.invalid = false;
    this.invalidMails = [];
    const { emails } = this;
    if (!emails) {
      this.invalid = true;
      return;
    }
    const es = Array.from(new Set(emails.split(';').filter((e) => e)));
    for (let i = 0; i < es.length; i++) {
      if (es[i].trim() && !this.regex.test(es[i])) {
        this.invalidMails.push(es[i]);
      }
    }

    if (this.invalidMails.length > 0) {
      this.invalid = true;
      this.working = false;
      return;
    }

    const timingMarker = this.timingMarkerService.start(
      new DashpivotEvent(EventTypes.FormSentAsPdf, { formId: this.formId }),
    );

    this.formService.sendFormAsPDF(this.templateId, es, this.params).subscribe(() => {
      this.timingMarkerService.finish(timingMarker);
      this.working = false;
      this.toastr.successByKey('sendFormPDF');
      this.modal.dismiss();
    });
  }
}
