import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AttachmentColumComponent } from './register/attachment-column.component';

@NgModule({
  declarations: [AttachmentColumComponent],
  imports: [CommonModule],
  exports: [AttachmentColumComponent],
})
export class AttachmentModule {}
