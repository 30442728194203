import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from 'app/shared/shared.module';

import { FormSignatureListDiffComponent } from './edit/diff/form-signature-list-diff.component';
import { FormSignatureListComponent } from './edit/form-signature-list.component';
import { SignatureArrayColumnComponent } from './register/signature-array-column.component';
import { SignatureArrayTemplatePreviewComponent } from './template/preview/signature-array-template-preview.component';

@NgModule({
  imports: [CommonModule, MomentModule, SharedModule, FormsModule],
  declarations: [
    FormSignatureListComponent,
    SignatureArrayColumnComponent,
    SignatureArrayTemplatePreviewComponent,
    FormSignatureListDiffComponent,
  ],
  exports: [FormSignatureListComponent, SignatureArrayTemplatePreviewComponent],
})
export class SignatureArrayModule {}
