import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormSingleLineInputDiffComponent } from './edit/diff/form-single-line-input-diff.component';
import { FormSingleInputComponent } from './edit/form-single-input.component';
import { AppTextComponent } from './template/app-text.component';
import { SingleLineInputTemplatePreviewComponent } from './template/preview/single-line-input-template-preview.component';
import { SingleLineInputThumbnailComponent } from './thumbnail/single-line-input-thumbnail.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  declarations: [
    SingleLineInputThumbnailComponent,
    FormSingleInputComponent,
    SingleLineInputTemplatePreviewComponent,
    AppTextComponent,
    FormSingleLineInputDiffComponent,
  ],
  exports: [
    SingleLineInputThumbnailComponent,
    FormSingleInputComponent,
    SingleLineInputTemplatePreviewComponent,
    AppTextComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SharedModule,
    SegmentModule,
    UIComponentsModule,
    FormFieldsCommonModule,
  ],
})
export class SingleLineInputModule {}
