import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { TooltipDirective } from '@site-mate/global-web-ui';

import { SegmentModule } from 'app/segment/segment.module';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { FormPersonDiffComponent } from './edit/diff/form-person-diff.component';
import { FormPersonComponent } from './edit/form-person.component';
import { PersonColumnHeaderFilterComponent } from './register/person-column-header-filter.component';
import { PersonColumnComponent } from './register/person-column.component';
import { PersonTemplateComponent } from './template/person-template.component';
import { PersonTemplatePreviewComponent } from './template/preview/person-template-preview.component';
import { PersonThumbnailComponent } from './thumbnail/person-thumbnail.component';
import { FormFieldsCommonModule } from '../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    SharedModule,
    SegmentModule,
    FormFieldsCommonModule,
    UIComponentsModule,
    TooltipDirective,
  ],
  declarations: [
    PersonTemplatePreviewComponent,
    PersonTemplateComponent,
    PersonColumnHeaderFilterComponent,
    FormPersonComponent,
    FormPersonDiffComponent,
    PersonColumnComponent,
    PersonThumbnailComponent,
  ],
  exports: [
    PersonTemplatePreviewComponent,
    PersonTemplateComponent,
    PersonColumnHeaderFilterComponent,
    FormPersonComponent,
    PersonColumnComponent,
  ],
})
export class PersonModule {}
