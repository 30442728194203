import { Component, Input } from '@angular/core';

import { AttachmentColumn } from './attachment-column';
import { FormFieldColumnComponent } from '../../common/register/form-field-column/form-field-column.component';

@Component({
  templateUrl: 'attachment-column.component.html',
  styleUrls: ['../../common/register/form-field-column/form-field-column.component.scss'],
})
export class AttachmentColumComponent extends FormFieldColumnComponent {
  @Input()
  column: AttachmentColumn;
}
