import { Component, Input, OnInit } from '@angular/core';

import { CategorySourceTypes, StyleVariants } from '@site-mate/dashpivot-shared-library';

import { FormFieldColumnComponent } from 'app/form-fields/common/register/form-field-column/form-field-column.component';

import { CategoryColumn } from './category-column';

@Component({
  templateUrl: 'category-column.component.html',
  styleUrls: [
    '../edit/form-category.component.scss',
    '../../common/register/form-field-column/form-field-column.component.scss',
    'category-column.component.scss',
  ],
})
export class CategoryColumnComponent extends FormFieldColumnComponent implements OnInit {
  @Input()
  column: CategoryColumn;

  hasSelectedItems: boolean;
  isLinkedToList: boolean;

  readonly styleVariants = StyleVariants;

  ngOnInit() {
    this.hasSelectedItems = this.column.selectedItems && !!this.column.selectedItems.length;
    this.isLinkedToList = this.column.listSource === CategorySourceTypes.List;
  }
}
