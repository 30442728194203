<div class="formula-helper-container" (click)="$event.stopPropagation()">
  <ng-container *ngIf="!selectedExample">
    <span>Select to see example:</span>
    <button
      type="button"
      class="formula-helper-label"
      (click)="selectExample(example, $event)"
      *ngFor="let example of examples"
    >
      {{ example.label }}
    </button>
  </ng-container>

  <ng-container *ngIf="selectedExample">
    <button
      type="button"
      *ngIf="selectedExample"
      class="sm-flex sm-flex-col sm-justify-center"
      (click)="clearExample()"
    >
      <i class="cc-icon cc-icon-arrow-left sm-rounded sm-bg-grey-1" aria-hidden="true"></i>
    </button>
    <span class="formula-helper-label">{{ selectedExample.label }}</span>
    <span class="formula-helper-syntax">{{ selectedExample.syntax }}</span>
    <div class="formula-helper-clipboard">
      <span class="formula-example">{{ selectedExample.formula }}</span>
      <button
        type="button"
        placement="bottom"
        tooltipClass="black-tooltip"
        [ngbTooltip]="toolTemplate"
        triggers="manual"
        (click)="copyToClipboard()"
        (mouseenter)="tooltip.open()"
        (mouseleave)="restoreToolTip()"
        [autoClose]="false"
      >
        <i class="align-middle cc-icon cc-icon-clone"></i>
      </button>
    </div>
    <a class="formula-help-link" [href]="selectedExample.link" rel="noreferrer" target="_blank">
      <i class="cc-icon cc-icon-question" aria-hidden="true"></i>
      <span> Watch video </span>
    </a>
  </ng-container>
</div>

<ng-template #toolTemplate>
  <span class="sm-text-sm sm-font-normal">{{ tooltipText }}</span>
</ng-template>
